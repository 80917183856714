import React from 'react'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link
  } from "react-router-dom";
import Button from './Buttons.js'
import Autocomplete from '@mui/material/Autocomplete';
import tags from '../data/tags.js'
import Quill from 'quill';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import {MdClose} from 'react-icons/md';
import Checkbox from '@mui/material/Checkbox';
import axios from 'axios';
import { BsLaptop, BsChevronLeft } from 'react-icons/bs'
import { Toggle } from './WhyWorkWithUsRecruiters.js'
import Slide from '@mui/material/Slide';

export const checkoutLinks = {
    cvpack: {link: "https://buy.stripe.com/14kg0Tbt03uidFuaEH", price: 89, name: "CV Review"},
    cvportfoliopack: {link: "https://buy.stripe.com/aEU5mf54C9SGati8wy", price: 119, name: "CV + Portfolio Review"},
    // linkedinpack: {link: "https://buy.stripe.com/3cs9B5bRj2O8enS00m", price: 99, name: "LinkedIn Review"},
    strategy: {link: "https://buy.stripe.com/bIYcOH1Sqgh40SI7st", price: 169, name: "Strategy Call | UK"},
    strategydubai: {link: "https://buy.stripe.com/cN28yr54C8OC1WMfYY", price: 189, name: "Strategy Call | Dubai"},
    call: {name: "15 Minute Call"}
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <MdClose />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const Transition = React.forwardRef(function Transition(props, ref) {
  const propsCopy = {...props}
  propsCopy.timeout = 500
  return <Slide direction="up" ref={ref} {...propsCopy} />;
});

class CoachingPayment extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            job_submission_status: {},
            success_open: false,
            package: "standard",
            showPaymentPopup: false,
            selectedPackage: null,
            currentCheckoutLink: null
        }
        this.quill = null
        this.multiSelectValues = {}
      }    
    
      componentDidMount = () => {
        if (!this.quill){
          this.quill = new Quill('#jobdescriptioninput', {
            theme: 'snow',
            formats: [
              'bold',
              'header',
              'italic',
              'link',
              'list',
              'blockquote',
              'image',
              'indent'
            ],
            modules: {
              toolbar: [
                [{'header': 1}, {'header': 2}],
                ['bold', 'italic', 'link'],
                ['blockquote', {'list': 'ordered'}, {'list': 'bullet'}],
              ],
              clipboard: {
                matchVisual: false // https://quilljs.com/docs/modules/clipboard/#matchvisual
              }
            }
          });
        }
        if (window.location.search){
          var urlParams = new URLSearchParams(window.location.search);
          var SelectedPackage = urlParams.get('package')
          if (SelectedPackage){
            this.setState({package: SelectedPackage})
          }
        }
        window.scrollTo(0, 0)
      }

      updateMultiSelectValue = (id, value) => {
        this.multiSelectValues[id] = value
      }

      Submit = () => {
        console.log("submitting")
        this.setState({job_submission_status: {status: "loading"}})

        const fields = [
            // {id: "company_name", field_name:"company_name", required: true, label: "Company Name"},
            // {id: "company_site", field_name:"company_site", required: true, label: "Company Site"},
            {id: "name", field_name:"contact_name", required: true, label: "Contact Name"},
            {id: "phone", field_name:"phone", required: true, label: "Contact Number"},
            {id: "email", field_name:"email", required: true, label: "Email Address"},
          ]

          var jobSubmission = {data: {}}
      
          for (const field of fields){
            var input = document.getElementById(field.id)
      
            if (input != null){
              if (field.type == "checkbox"){
                jobSubmission.data[field.field_name] = input.checked ? 1: 0
              } else if(field.type == "numeric") {
                var int_value = parseInt(input.value)
                if (int_value < 0){
                  int_value = null
                }
                jobSubmission.data[field.field_name] = int_value
              } else {
                if ((input.value == "") && (field.required == true)){
                  this.setState({job_submission_status: {status: "error", message: "Required field "+field.label+" not filled out", field: field.id, field_message: "Required Field"}})
                  return
                } else {
                  jobSubmission.data[field.field_name] = input.value
                }
                jobSubmission.data[field.field_name] = input.value
              }
            } else {
              console.log("Couldnt get input: ", field.id)
            }
        }
    
        jobSubmission.data["package"] = checkoutLinks[this.state.package].name + " - Coaching"
        //job description
        if (this.quill){
          var job_description_content = this.quill.root.innerHTML
          // console.log(job_description_content)
          jobSubmission.data["job_description"] = job_description_content
        }
        console.log(jobSubmission)
        const SubmitUrl = "https://api.archjobs.co.uk/customer_signup" 

        const checkoutLink = checkoutLinks[this.state.package].link

        axios.post(SubmitUrl, {
            body: JSON.stringify(jobSubmission),
            headers: {
              "Content-type": "application/json; charset=UTF-8",
            }
          }).then(response => {
            console.log(response.data)
            if ("error" in response.data){
                this.setState({job_submission_status: {status: "error", message: "Could not submit form, please email us at hello@archjobs.co.uk and let us know!"}})
            } else {
                this.setState({
                  success_open: true, 
                  job_submission_status: {status: "success"},
                  currentCheckoutLink: checkoutLink
                })
                if (checkoutLink){
                  window.open(checkoutLink, '_blank');
                } else {
                  // wait 3 seconds and navigate back to /coaching
                  setTimeout(() => {
                    window.location.href = "/architectural-career-coaching"
                  }, 3000);
                }
            }
          }).catch(error =>{
            this.setState({job_submission_status: {status: "error", message: "Could not submit form, please email us at hello@archjobs.co.uk instead"}})
          })
      }

      handleClose = () => {
        this.setState({success_open: false})
      }

      handlePackageChange = (newPackage) => {
        this.setState({package: newPackage})
      }

      handlePackageSelect = (packageType) => {
        this.setState({
          showPaymentPopup: true,
          selectedPackage: packageType
        });
      };

      handleClosePaymentPopup = () => {
        this.setState({
          showPaymentPopup: false,
          selectedPackage: null
        });
      };

    render() {

    var error_text = ""

    const checkoutSelectedRange = checkoutLinks

      return (
        <div className="PostJobPage">
          <Link className="ResultsGoBack" to={-1} ><BsChevronLeft className="BackArrow"/>Go Back</Link>
            <div className="PostJobFormContainer">
                <div className="PostJobForm">
                    <div className="PostJobFormHeader"> 
                      You have selected: {this.state.package && checkoutSelectedRange[this.state.package] && checkoutSelectedRange[this.state.package].name}
                    </div>
                    {this.state.package == "call"?<><div className="PostJobFormText"> 
                    Not sure if booking a Career Coaching Session is the right step for you? No problem, let's arrange a 15-minute free call and find out.
                    </div>
                    <div className="PostJobFormText"> 
                      Please fill out the Contact Form below, letting us know when you're available and what you need help with. Aylin will get back to you to schedule a convenient time and date for your chat.
                    </div></>:<><div className="PostJobFormText"> 
                      Thank you for choosing our Career Coaching Service. 
                    </div>
                    <div className="PostJobFormText"> 
                      Please fill out the form below and click "Check Out" to make your payment via our secure payment provider, Stripe.
                    </div>
                    <div className="PostJobFormText"> 
                    Once the payment has been processed, Aylin will reach out to you via email to schedule the coaching session as soon as possible. 🙂 
                    </div></>}
 
                    <div className="PostJobFormDivider"></div>
                    <div className="PostJobFormHeader">
                        Contact Form
                    </div>
                    <div className="PostJobFormInputRow">
                        <div className="PostJobFormInputTitle">Contact Name</div>
                        <TextField
                            variant="outlined"
                            id="name"
                            className="PostJobInput"
                            type="text"
                            placeholder="Full Name"
                            error={this.state.job_submission_status.field == "name"}
                            helperText={(this.state.job_submission_status.field == "name") && this.state.job_submission_status.field_message}
                            />
                    </div>
                    <div className="PostJobFormInputRow">
                        <div className="PostJobFormInputTitle">Contact Number</div>
                        <TextField
                            variant="outlined"
                            id="phone"
                            className="PostJobInput"
                            type="text"
                            placeholder="Contact Number"
                            error={this.state.job_submission_status.field == "phone"}
                            helperText={(this.state.job_submission_status.field == "phone") && this.state.job_submission_status.field_message}
                            />
                    </div>
                    <div className="PostJobFormInputRow">
                        <div className="PostJobFormInputTitle">Email Address</div>
                        <TextField
                            variant="outlined"
                            id="email"
                            className="PostJobInput"
                            type="email"
                            placeholder="Contact Email"
                            error={this.state.job_submission_status.field == "email"}
                            helperText={(this.state.job_submission_status.field == "email") && this.state.job_submission_status.field_message}
                            />
                    </div>
                    <div className="PostJobFormInputRow">
                        <div className="PostJobFormInputTitle">Extra Information (Optional) 
                          <div className="JobSubmissionsubtitle">
                          {this.state.package == "call"?"Let us know when is a good time for a call, and what you need help with" :"Please provide Aylin with some background information such what you need help with, your current situation and any other relevant information."}
                          </div> 
                        </div>
                        <div className="JobDescriptionInputContainer">
                            <div id="jobdescriptioninput" className="JobDescriptionInput" ></div>
                        </div>
                    </div>
                    {/* <div className="PostJobFormDivider"></div>
                    <div className="PostJobFormHeader">
                        Your Package
                    </div>
                    <div className="PostJobFormText"> 
                        We will be in contact once payment is confirmed to set up a time for your coaching session.
                    </div>
                    <div className="PostJobFormInputRow">
                        <div className="PostJobFormInputTitle">Package</div>
                        <div className="PostJobPackageContainer">
                            <div className={"PostJobPackage"+(this.state.package == "strategy"?" PostJobPackageSelected":"")} onClick={() => {this.handlePackageChange("strategy")}} id="strategy" >
                              <div className="PackageTitle">Strategy Call</div>
                              <div className="PackagePrice">£{checkoutSelectedRange.strategy.price}</div>
                              <div className="PackagePerAdSubtext">One-off payment</div>
                            </div>
                            <div className={"PostJobPackage"+(this.state.package == "starter"?" PostJobPackageSelected":"")} onClick={() => {this.handlePackageChange("starter")}} id="starter" >
                              <div className="PackageTitle">Starter Pack</div>
                              <div className="PackagePrice">£{checkoutSelectedRange.starter.price}</div>
                              <div className="PackagePerAdSubtext">One-off payment</div>
                            </div>
                            <div className={"PostJobPackage"+(this.state.package == "interview"?" PostJobPackageSelected":"")} onClick={() => {this.handlePackageChange("interview")}} id="interview" >
                              <div className="PackageTitle">Interview Pack</div>
                              <div className="PackagePrice">£{checkoutSelectedRange.interview.price}</div>
                              <div className="PackagePerAdSubtext">One-off payment</div>
                            </div>
                            <div className={"PostJobPackage"+(this.state.package == "full"?" PostJobPackageSelected":"")} onClick={() => {this.handlePackageChange("full")}} id="full" >
                              <div className="PackageTitle">Full Pack</div>
                              <div className="PackagePrice">£{checkoutSelectedRange.full.price}</div>
                              <div className="PackagePerAdSubtext">One-off payment</div>
                            </div>  
                        </div>
                    </div>
                    <div className="PostJobFormSubmit">
                        <a href="/architectural-career-coaching" target="_blank" className="WhyWorkWithUsLink" style={{textDecoration: "underline"}}>What is included?</a>
                    </div> */}
                    <div className="PostJobFormSubmit"> 
                        <Button btnClassName="JobAdHeaderApplyButton" onClickFunc = {() => {this.Submit()}}>{this.state.package == "call"? "Request Call":"Check Out"}</Button>
                    </div>
                    <div className="PostJobFormError"> 
                        {(this.state.job_submission_status.status == "error") && this.state.job_submission_status.message}
                    </div>
                </div>
            </div>
            <BootstrapDialog
                onClose={this.handleClose}
                aria-labelledby="customized-dialog-title"
                open={this.state.success_open}
                className="SuccessModal"
                PaperProps={{
                  sx: {
                    maxWidth: '500px',
                    padding: '2rem',
                    '& .SuccessModalTitle': {
                      fontSize: '1.8rem',
                      marginBottom: '1rem',
                      textAlign: 'center'
                    },
                    '& .SuccessModalText': {
                      fontSize: '1.2rem',
                      lineHeight: 1.4,
                      marginBottom: '1rem',
                      textAlign: 'center'
                    },
                    '& .PaymentLink': {
                      color: '#2D63BA',
                      textDecoration: 'underline',
                      cursor: 'pointer',
                      marginTop: '1rem',
                      display: 'block',
                      textAlign: 'center'
                    }
                  }
                }}
            >
                <div className="ModalClose">
                  <MdClose className="CloseIcon" onClick={this.handleClose}></MdClose>
                </div>
                {this.state.package == "call"? 
                  <>
                    <div className="SuccessModalTitle">Thanks for Registering for a Call</div>
                    <div className="SuccessModalText">Aylin will be in contact soon</div>
                  </>
                  : 
                  <>
                    <div className="SuccessModalTitle">One more step...</div>
                    <div className="SuccessModalText">
                      Please proceed to the payment page that opened in a new tab to complete your purchase.
                      <p/>
                      If the payment page didn't open automatically, you can <a href={this.state.currentCheckoutLink} target="_blank" rel="noopener noreferrer" className="PaymentLink">click here to open it.</a>
                      <p/>
                      If you encounter any issues, please email aylin@archjobs.co.uk
                    </div>
                  </>
                }
            </BootstrapDialog>
        </div>
      )
    }
  }

export function CoachingPaymentPopup({ open, handleClose, packageType }) {
  const getPackageDetails = () => {
    switch(packageType) {
      case 'cv-review':
        return {
          title: 'CV Review',
          price: '£99',
          description: 'Expert review of your CV to highlight your strengths and improve your chances of landing interviews.'
        };
      case 'cv-portfolio-review':
        return {
          title: 'CV + Portfolio Review Session',
          price: '£149',
          description: 'Comprehensive review of both your CV and portfolio to maximize your impact on potential employers.'
        };
      case 'strategy-uk':
        return {
          title: 'Strategy Call | UK',
          price: '£199',
          description: 'One-on-one strategy session focused on the UK market to accelerate your architecture career.'
        };
      case 'strategy-dubai':
        return {
          title: 'Strategy Call | Dubai',
          price: '£199',
          description: 'Tailored strategy session for architects looking to break into or advance in the Dubai market.'
        };
      default:
        return {
          title: 'Coaching Session',
          price: 'Price varies',
          description: 'Please select a specific package to see details.'
        };
    }
  };

  const packageDetails = getPackageDetails();
  const paymentLink = checkoutLinks[packageType].link;

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="payment-dialog-description"
      className="PaymentPopup"
      PaperProps={{
        sx: {
          maxWidth: '500px',
          padding: '2rem',
          '& .PopUpTitle': {
            fontSize: '1.8rem',
            marginBottom: '1rem',
            textAlign: 'center'
          },
          '& .PopUpText': {
            fontSize: '1.2rem',
            lineHeight: 1.4,
            marginBottom: '2rem',
            textAlign: 'center'
          },
          '& .Price': {
            fontSize: '2rem',
            fontWeight: 'bold',
            textAlign: 'center',
            marginBottom: '1.5rem',
            color: '#2E7D32'
          }
        }
      }}
    >
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: 'grey.500',
        }}
      >
        ✕
      </IconButton>

      <div className="PopUpContent">
        <h1 className="PopUpTitle">{packageDetails.title}</h1>
        <h2 className="Price">{packageDetails.price}</h2>
        <div className="PopUpText">
          <p>{packageDetails.description}</p>
        </div>
        <div style={{ textAlign: 'center' }}>
          <Button 
            btnClassName="SearchButton"
            onClickFunc={() => window.location.href = paymentLink}
          >
            Proceed to Payment
          </Button>
        </div>
      </div>
    </Dialog>
  );
}

export default CoachingPayment