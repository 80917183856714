import React, { Component, useEffect } from 'react';
import '../css/overtime.css'
import { Input, TextField, InputAdornment, Select, MenuItem } from '@mui/material';
import Button from './Buttons';
import { Collapse } from '@mui/material';
import { BsChevronDown, BsChevronUp} from 'react-icons/bs'
import { articleContent } from '../articles/LivingWages.js';
import { JumpToID } from '../functions/JumpToID.mjs';
import { Helmet } from 'react-helmet';

const Check = <svg className="Check" stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="m10 15.586-3.293-3.293-1.414 1.414L10 18.414l9.707-9.707-1.414-1.414z"></path></svg>
const Cross = <svg className="Cross" stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="m16.192 6.344-4.243 4.242-4.242-4.242-1.414 1.414L10.535 12l-4.242 4.242 1.414 1.414 4.242-4.242 4.243 4.242 1.414-1.414L13.364 12l4.242-4.242z"></path></svg>

const salaryPeriods = [
    {
        "name": "per Year",
        "value": 1
    },
    {
        "name": "per Month",
        "value": 12
    },
    {
        "name": "per Week",
        "value": 52
    }
]

const locations = [
    {
        "name": "London",
        "value": "London"
    },
    {
        "name": "Outside London",
        "value": "Outside London"
    }
]

const minimumWages = {
    "21+": 12.21,
    "18-20": 10.00,
    // "18-20": 7.49,
    "under 18": 7.55,
}

const livingWages = {
    "London": 13.85,
    "Outside London": 12.60
}

const OvertimeCalculator = () => {
    
        const [hours, setHours] = React.useState(37.5)
        const [salary, setSalary] = React.useState('')
        const [salaryPeriod, setSalaryPeriod] = React.useState("per Year")
        const [unpaidHours, setUnpaidHours] = React.useState('')
        const [age, setAge] = React.useState("23+")
        const [location, setLocation] = React.useState("Outside London")
        const [error, setError] = React.useState("")
        const [open, setOpen] = React.useState(false);
        const [open2, setOpen2] = React.useState(false);
        const [overTimeResults, setOverTimeResults] = React.useState({calculated: false})
    
        const setErrorMessage = (message) => {
            setError(message)
            setTimeout(() => {
                setError("")
            }, 5000);
        }

        const calculate = () => {
            console.log(hours, salary, salaryPeriod, unpaidHours)
            let salaryInt = parseFloat(salary.replace(/,/g, ''))
            if (!salaryInt){
                setErrorMessage("Please enter a valid salary")
                return
            }
            let unpaidHoursInt = parseFloat(unpaidHours)
            if (!unpaidHoursInt){
                setErrorMessage("Please enter a valid number of unpaid hours")
                return
            }
            let hoursfloat = parseFloat(hours)
            if (!hoursfloat){
                setErrorMessage("Please enter a valid number of contracted hours")
                return
            }
            const results = {}
            results.calculated = true
            let salaryPerHour = salaryInt / (hoursfloat * (52/salaryPeriods.find((period) => {return period.name === salaryPeriod}).value))
            results.salary = salaryInt * salaryPeriods.find((period) => {return period.name === salaryPeriod}).value
            console.log(salaryPerHour)
            results.salaryPerHour = salaryPerHour
            // effective salary per hour
            results.unpaidHours = unpaidHoursInt
            results.effectiveSalaryPerHour = salaryPerHour * hoursfloat / (hoursfloat + unpaidHoursInt)
            results.effectiveSalary = results.effectiveSalaryPerHour * hoursfloat * 52
            if ( results.effectiveSalaryPerHour < minimumWages[age]){
                results.underMinimumWage = true
            } else {
                results.underMinimumWage = false
            }
            if ( results.effectiveSalaryPerHour < livingWages[location]){
                results.underLivingWage = true
            } else {
                results.underLivingWage = false
            }
            
            // maximum hours worked until under minimum wage
            results.maxOvertimeHours = salaryPerHour / minimumWages[age] * hoursfloat - hoursfloat

            results.maxLivingWageHours = salaryPerHour / livingWages[location] * hoursfloat - hoursfloat
            // salary if paid for overtime
            results.salaryWithOvertime = salaryPerHour * (hoursfloat + unpaidHoursInt) * 52
            console.log("results: ", results)
            setOverTimeResults(results)
        }

        useEffect(() => {
            if (overTimeResults.calculated){
                calculate()
            }
        }, [age, location, hours, salary, salaryPeriod, unpaidHours])

    
        return (
            <div className="OvertimeCalculator">
                <div className="OvertimeCalculatorInputContainer">
                    <div className="OvertimeCalculatorInput">
                        <div className="OvertimeCalculatorInputTitle">
                            Contracted Hours per week
                        </div>
                        <TextField
                            id="outlined-number"
                            // label="Hours"
                            className="PostJobInput"
                            type="number"
                            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            variant="outlined"
                            value={hours}
                            onChange={(e) => {setHours(e.target.value)}}
                        />
                    </div>
                    <div className="OvertimeCalculatorInput">
                        <div className="OvertimeCalculatorInputTitle">
                            Salary
                        </div>
                        <div className="OvertimeCalculatorInputSalary">
                            <TextField
                                id="outlined-number-salary"
                                // label="Salary"
                                className="PostJobInput"
                                type="number"
                                inputProps={{ inputMode: 'numeric', pattern: '[0-9,.]*', startAdornment: <InputAdornment position="start">£</InputAdornment> }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="outlined"
                                value={salary}

                                onChange={(e) => {setSalary(e.target.value)}}/>
                                <Select className="PostJobInput" value={salaryPeriod} onChange={(e) => {setSalaryPeriod(e.target.value)}}>
                                    {salaryPeriods.map((period) => {
                                        return <MenuItem value={period.name}>{period.name}</MenuItem>
                                    })}
                                </Select>

                        </div>
                        
                    </div>
                    <div className='OvertimeCalculatorInput'>
                        <div className="OvertimeCalculatorInputTitle">
                            Unpaid Hours Worked per Week (Average)
                        </div>
                        <TextField
                            id="outlined-number"
                            // label="Hours"
                            className="PostJobInput"
                            type="number"
                            inputProps={{ inputMode: 'numeric', pattern: '[0-9,.]*' }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            variant="outlined"
                            value={unpaidHours}
                            onChange={(e) => {setUnpaidHours(e.target.value)}}/>
                    </div>
                    <div className='OvertimeCalculatorInput'>
                        <div className="OvertimeCalculatorInputTitle">
                            Your Age Bracket (for minimum wage calculations)
                        </div>
                        <Select className="PostJobInput" value={age} onChange={(e) => {setAge(e.target.value)}}>
                            {Object.keys(minimumWages).map((age) => {
                                return <MenuItem value={age}>{age}</MenuItem>
                            })}
                        </Select>
                    </div>
                    <div className='OvertimeCalculatorInput'>
                        <div className="OvertimeCalculatorInputTitle">
                            Your Location (for living wage calculations)
                        </div>
                        <Select className="PostJobInput" value={location} onChange={(e) => {setLocation(e.target.value)}}>
                            {locations.map((location) => {
                                return <MenuItem value={location.value}>{location.name}</MenuItem>
                            })}
                        </Select>
                    </div>
                    <div className="OvertimeCalculatorButton">
                        <Button btnClassName="JobAdHeaderApplyButton" onClickFunc = {() => {calculate()}}>Calculate</Button>
                    </div>
                </div>
                {overTimeResults.calculated ? <div className="OvertimeCalculatorOutput">
                    <div className="OvertimeCalculatorOutputTitle">
                        Results
                    </div>
                    <div className="OvertimeCalculatorOutputContainer">
                        <div className="OvertimeCalculatorOutputSection">
                            <div className="OvertimeCalculatorOutputSectionTitle">
                                Current
                                <div className="OvertimeCalculatorOutputSectionSubtitle">
                                    What your contract says
                                </div>
                            </div>
                            <div className="OvertimeCalculatorOutputSectionRow">
                                Hourly Rate:
                                <span className="OvertimeCalculatorOutputSectionRowValue">
                                    {overTimeResults.salaryPerHour.toLocaleString("en-GB", {style: "currency", maximumFractionDigits: 2, currency: "GBP"})}
                                </span>
                            </div>
                            <div className="OvertimeCalculatorOutputSectionRow">
                                Annual Salary: 
                                <span className="OvertimeCalculatorOutputSectionRowValue">
                                    {overTimeResults.salary.toLocaleString("en-GB", {style: "currency", maximumFractionDigits: 2, currency: "GBP"})}
                                </span>
                            </div>
                        </div>
                        <div className="OvertimeCalculatorOutputSection">
                            <div className="OvertimeCalculatorOutputSectionTitle">
                                Reality
                                <div className="OvertimeCalculatorOutputSectionSubtitle">
                                    What your time is really worth
                                </div>
                            </div>
                            <div className="OvertimeCalculatorOutputSectionRow">
                                Hourly Rate:
                                <span className="OvertimeCalculatorOutputSectionRowValue">
                                    {overTimeResults.effectiveSalaryPerHour.toLocaleString("en-GB", {style: "currency", maximumFractionDigits: 2, currency: "GBP"})}
                                </span>
                            </div>
                            <div className="OvertimeCalculatorOutputSectionRow">
                                Effective Salary:
                                <span className="OvertimeCalculatorOutputSectionRowValue">
                                    {overTimeResults.effectiveSalary.toLocaleString("en-GB", {style: "currency", maximumFractionDigits: 2, currency: "GBP"})}
                                </span>
                            </div>
                        </div>
                        {/* <div className="OvertimeCalculatorOutputSection">
                            <div className="OvertimeCalculatorOutputSectionTitle">
                                Ideal
                                <div className="OvertimeCalculatorOutputSectionSubtitle">
                                    Paid your hourly rate for overtime
                                </div>
                            </div>
                            <div className="OvertimeCalculatorOutputSectionRow">
                                Extra per week:
                                <span className="OvertimeCalculatorOutputSectionRowValue">
                                    {(overTimeResults.salaryPerHour * overTimeResults.unpaidHours).toLocaleString("en-GB", {style: "currency", maximumFractionDigits: 2, currency: "GBP"})}
                                </span>
                            </div>
                            <div className="OvertimeCalculatorOutputSectionRow">
                                Annual Salary:
                                <span className="OvertimeCalculatorOutputSectionRowValue">
                                    {overTimeResults.salaryWithOvertime.toLocaleString("en-GB", {style: "currency", maximumFractionDigits: 2, currency: "GBP"})}
                                </span>
                            </div>
                        </div> */}

                        <div className="OvertimeCalculatorOutputSection">
                            <div className="OvertimeCalculatorOutputSectionTitle" onClick={() => {setOpen(!open)}}>
                                <div className="OvertimeCalculatorOutputSectionTitleTop">{overTimeResults.underMinimumWage ? Cross:Check} Minimum Wage</div>
                                <div className="OvertimeCalculatorOutputSectionSubtitle">
                                    Legally Required to be paid
                                </div>
                                <div className="ExpandButton"  >
                                    {open ? <BsChevronUp className="ExpandArrow"/>:<BsChevronDown className="ExpandArrow"/>}
                                </div>
                            </div>
                            <Collapse in={open}>
                                <div className = "OvertimeCalculatorOutputSectionDropdown">
                                    <div className="OvertimeCalculatorOutputSectionRow">
                                        Minimum Wage: 
                                        <span className={overTimeResults.underMinimumWage ? "OvertimeCalculatorOutputSectionRowValue Warning": "OvertimeCalculatorOutputSectionRowValue"}>
                                            {minimumWages[age].toLocaleString("en-GB", {style: "currency", maximumFractionDigits: 2, currency: "GBP"})}
                                        </span>
                                    </div>
                                    <div className="OvertimeCalculatorOutputSectionRow">
                                        Maximum Overtime: 
                                        <span className={overTimeResults.underMinimumWage ? "OvertimeCalculatorOutputSectionRowValue Warning": "OvertimeCalculatorOutputSectionRowValue"}>
                                            {overTimeResults.maxOvertimeHours.toFixed(2)} hours/week
                                        </span>
                                    </div>  
                                    <div className="OvertimeCalculatorOutputText">
                                        {overTimeResults.underMinimumWage ? "You are currently paid under the minimum wage":"Exceeding this puts you under the minimum wage"}  
                                    </div>
                                    {overTimeResults.underMinimumWage ? <div className="OvertimeCalculatorOutputLink" onClick={() => {JumpToID("UnderMinimumWage")}}>
                                        Get Advice
                                    </div>:<div className="OvertimeCalculatorOutputLink" onClick={() => {JumpToID("MinimumWage")}}>
                                        Learn More
                                    </div>}
                                </div>
                            </Collapse>
                            
                        </div>
                        <div className="OvertimeCalculatorOutputSection">
                            <div className="OvertimeCalculatorOutputSectionTitle" onClick={() => {setOpen(!open)}}>
                                <div className="OvertimeCalculatorOutputSectionTitleTop">{overTimeResults.underLivingWage ? Cross:Check} Living Wage</div>
                                <div className="OvertimeCalculatorOutputSectionSubtitle">
                                    Required by RIBA Chartered Practices
                                </div>
                                <div className="ExpandButton"  >
                                    {open ? <BsChevronUp className="ExpandArrow"/>:<BsChevronDown className="ExpandArrow"/>}
                                </div>
                            </div>
                            <Collapse in={open}>
                                <div className = "OvertimeCalculatorOutputSectionDropdown">
                                    <div className="OvertimeCalculatorOutputSectionRow">
                                        Living Wage: 
                                        <span className={overTimeResults.underLivingWage ? "OvertimeCalculatorOutputSectionRowValue Warning": "OvertimeCalculatorOutputSectionRowValue"}>
                                            {livingWages[location].toLocaleString("en-GB", {style: "currency", maximumFractionDigits: 2, currency: "GBP"})}
                                        </span>
                                    </div>
                                    <div className="OvertimeCalculatorOutputSectionRow">
                                        Maximum Overtime: 
                                        <span className={overTimeResults.underLivingWage ? "OvertimeCalculatorOutputSectionRowValue Warning": "OvertimeCalculatorOutputSectionRowValue"}>
                                            {overTimeResults.maxLivingWageHours.toFixed(2)} hours/week
                                        </span>
                                    </div>    
                                    <div className="OvertimeCalculatorOutputText">
                                        {overTimeResults.underLivingWage ? "You are currently paid under the living wage":"Exceeding this puts you under the living wage"}
                                    </div>
                                    {overTimeResults.underLivingWage ? <div className="OvertimeCalculatorOutputLink" onClick={() => {JumpToID("UnderLivingWage")}}>
                                        Get Advice
                                    </div>:<div className="OvertimeCalculatorOutputLink" onClick={() => {JumpToID("LivingWage")}}>
                                        Learn More
                                    </div>}
                                </div>
                            </Collapse>
                        </div>
             
                    </div>
                </div>: <div className="OvertimeCalculatorOutput">
                    {error ? <div className="OvertimeCalculatorOutputMessage Warning">
                        {error}
                    </div>:<div className="OvertimeCalculatorOutputMessage">
                        Input your details and press Calculate to see your results
                    </div>}
                    
                    </div>}  

                <div className="ArticlePage">
                    <div className="ArticleContainer">
                        <h2 className="ArticleTitle">Understanding Overtime & the Living Wage</h2>
                        {articleContent}
                    </div>

                </div>
            </div>
        )
    }




const OvertimeCalculatorPage = () => {

    let desc = "Calculate the real cost of your unpaid overtime and see if you are entitled to more pay. An Unpaid Overtime Calculator for Architects."

    return (
        <div className="OvertimeCalculatorPage">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Unpaid Overtime Calculator | ArchJobs  </title>
                <link rel="canonical" href={"https://www.archjobs.co.uk"+window.location.pathname} />
                <meta name="description" content={desc }/>
                <meta name="og:description" content={desc}/>
                <meta name="og:title" content={`Unpaid Overtime Calculator | ArchJobs`}/>
                <meta name="og:type" content={"website"}/>
                <meta name="og:url" href={"https://www.archjobs.co.uk"+window.location.pathname}/>
            </Helmet>
            <div className="OvertimeCalculatorContainer">
                <h1 className="OvertimeCalculatorTitle">
                    Unpaid Overtime Calculator
                </h1>
                <div className="OvertimeCalculatorDescription">
                    Calculate the real cost of your unpaid overtime and see if you are entitled to more pay
                </div>
                <OvertimeCalculator/>
            </div>
        </div>
    )
}


export default OvertimeCalculatorPage

