


const JobTitles = ['Intern', 'Principal Designer', 'CDM Principal Designer', 'Architect', "Document Controller", 'BIM Technician','Architectural Assistant', 'Architectural Designer', 'Director of Projects', 'Masterplanner', 'Architectural Visualiser', "Architectural Technician", "Architectural Technologist", "Graduate Architectural Technologist",'Associate Architect', 'Associate Director', "Bid Coordinator", "Bid Manager", "Bid and Marketing Coordinator", "Bid and Marketing Manager", 'Bid Writer/Assistant', 'BIM Manager', 'BIM Specialist', 'BIM Coordinator/Consultant', 'Conservation Architect', 'Design Architect', 'Design Manager', 'Director', 'Graduate BIM', 'Landscape Architect', 'Graduate Landscape Architect', 'Part 1 Architectural Assistant', 'Part 2 Architectural Assistant', "Part 3", "Passivhaus Designer", 'Project Architect', 'Senior Architect', "Senior Architectural Technician", "Senior Architectural Technologist", "Senior Landscape Architect", "Senior Urban Designer", 'Sustainability', 'Sustainability Manager', 'Head of Sustainability', "Technical Architect", "Technical Project Lead", "Urban Designer"]

const JobTitlesShortlist = ['Architect', 'CDM Principal Designer', 'Principal Designer', "Document Controller", 'BIM Technician',"Architectural Technologist",'Associate Architect', 'BIM Manager', 'BIM Coordinator/Consultant', 'Director', 'Landscape Architect', 'Part 1 Architectural Assistant', 'Part 2 Architectural Assistant', "Part 3", 'Project Architect', 'Senior Architect', "Senior Architectural Technologist", "Senior Landscape Architect", "Urban Designer", "Intern"]

const Sectors = ['Blue Light', 'Media', 'Pharmaceutical', 'Defence', 'Residential', 'High-End Residential', 'Transport', 'Commercial', 'Mixed-Use', 'Office', 'Student Accommodation', 'MOD', 'Hospitality', 'Custodial','Healthcare', 'Education', 'Higher Education', 'Nuclear', 'Industrial', 'Leisure', 'Hotel', 'Leisure/Hotel', 'Sport', 'Retail', 'Conservation', 'Manufacturing', "Private Residential", "Aviation", "Science", "Logistics", "High-Rise Residential" ,"Cultural", "Masterplans", "Infrastructure", "Regeneration", "Data Centre", "Heritage", "Community", "Care", "Culture", "Urban", 'Workplace', "Civic", "Landscape", "Public Realm", "Later Living", 'Green Infrastructure', "Housing", 'Public', 'Laboratory', 'Research', 'Technology']

const Software = ["CAD", "Twinmode", 'Bentley', 'Blender', 'Bluebeam', 'Revit', 'NBS Chorus', "Rhino", 'AutoCAD', "ArchiCAD", 'Photoshop', 'ProjectWise', 'InDesign', 'Illustrator', 'Enscape', 'SketchUp', 'Vectorworks', 'MicroStation', "Lumion", 'V-Ray', 'Navisworks', 'Dynamo', 'Solibri', 'BIM360', "Adobe After Effects", "Grasshopper", "Unreal", "Adobe Creative Suite", "3D Max", "Affinity", "Insight", "Sefaira", "Diva", "Ladybug", "C#", "Python"]

const WorkArrangements = ['Office Based', 'Fully Remote', 'Flexible Schedule', 'Hybrid', 'Work From Home']

const JobType = ['Full-Time', 'Part-Time', 'Contract', 'Fixed Term Contract']

const ExperienceLevel = [ "Graduate", "Junior", "Mid-Level", "Senior", "Apprentice", "Internship"]

const OtherTags = ["Multi-Disciplinary", "Housing Developer", "AJ100", "IR35", "Featured", "Passivhaus", "Small Team", "Sustainability", "Central Location", "Excellent Benefits", "B-Corp", "Employee Owned"]

const ExperienceLevels = [{min: 0, max: 1, name: "1 Year"}, {min: 1, max: 2, name: "1+ Years"}, {min: 2, max: 3, name: "2+ Years"}, {min: 3, max: 5, name: "3+ Years"}, {min: 5, max: 7, name: "5+ Years"}, {min: 7, max: 9, name: "7+ Years"}, {min: 9, max: 11, name: "9+ Years"}, {min: 11, max: 13, name: "11+ Years"}, {min: 13, max: 15, name: "13+ Years"}, {min: 15, max: 17, name: "15+ Years"}, {min: 17, max: 19, name: "17+ Years"}, {min: 19, max: 21, name: "19+ Years"}, {min: 21, max: 23, name: "21+ Years"}, {min: 23, max: 25, name: "23+ Years"}, {min: 25, max: 30, name: "25+ Years"}] 

export default {
    JobTitles,
    Sectors,
    Software,
    WorkArrangements,
    JobType,
    OtherTags,
    ExperienceLevel,
    ExperienceLevels
}