import ArticleFrame from "./ArticleFrame";
import React, { Component } from 'react';


export default class JobTitlesArchitectUK extends React.Component {
    constructor(props) {
      super(props);
      this.state = { 
      };
    }
    componentDidMount = () => {
      window.scrollTo(0, 0)
    }

    render(){
        return <ArticleFrame ArticleDataName="JobTitlesArchitectUK" >
            <p>If you&rsquo;ve trained or worked as an architect outside the UK or you&rsquo;re a recent graduate navigating the early stages of your architectural career - this is a really important one to be aware of.</p>
            <p>In the UK, the title &ldquo;Architect&rdquo; is legally protected. That means you cannot use it in your job title, on your CV, or on LinkedIn unless you&rsquo;re registered with the Architects Registration Board (ARB). And yes, this applies even if you&rsquo;ve studied architecture for 5+ years or have years of experience abroad.</p>
            <p/>
            <h3>What You Can Expect in This Article</h3>
            <p>I&rsquo;ll break down what the ARB rule means, which job titles are (and aren&rsquo;t) acceptable in the UK and what you should be using instead - whether you&rsquo;re a Part 1, Part 2, on an apprenticeship, or qualified overseas but not yet registered.</p>
            <p/>
            <p/>
            <h3>Why Is the Title &ldquo;Architect&rdquo; Protected?</h3>
            <p>In the UK, only those who are registered with the ARB can legally use the title &ldquo;Architect&rdquo;. This protects the integrity of the profession and ensures that those using the title meet strict educational, practical and ethical standards. It&rsquo;s not just a formality - it&rsquo;s the law.</p>
            <p>Even if you&rsquo;re fully qualified in another country, unless you&rsquo;ve completed the ARB&rsquo;s registration process, you&rsquo;re not legally allowed to refer to yourself as an Architect in any formal or professional context.</p>
            <p/>
            <p>Thinking of registering? If you&rsquo;re an overseas-qualified professional, you may be eligible to join the ARB register depending on your qualifications and experience. It&rsquo;s worth looking into if you plan to stay in the UK long term.</p>
            <p/>
            <p/>
            <h3>Does This Apply to Everyone?</h3>
            <p>Only if you&rsquo;re working in or applying for roles in the UK.</p>
            <p>This rule applies to professionals who are currently based in the UK or plan to work here. If you&rsquo;ve previously held the title &ldquo;Architect&rdquo; in your home country or on past projects, that&rsquo;s completely fine to keep listed on your CV and LinkedIn profile as long as it&rsquo;s clear that the role was based overseas.</p>
            <p>You&rsquo;re not expected to rewrite your professional history. Just make sure you&rsquo;re using the correct terminology moving forward if you&rsquo;re job hunting or working in the UK.</p>
            <p/>
            <p/>
            <h3>Common Mistakes: What Not to Use</h3>
            <p>Unfortunately, I see this all the time on CVs and LinkedIn profiles. Titles that might be acceptable in other countries are not recognised or are outright misleading in the UK.</p>
            <p>You cannot use:</p>
            <ul>
            <li>Junior Architect</li>
            <li>Intern Architect</li>
            <li>Graduate Architect</li>
            <li>Part 1 Architect</li>
            <li>Part 2 Architect</li>
            <li>Assistant Architect</li>
            <li>L6 or L7 Architect (Apprenticeship roles)</li>
            </ul>
            <p>Even though these may seem accurate or harmless, they&rsquo;re not permitted under UK law.</p>
            <p/>
            <p/>
            <h3>So What Should You Use Instead?</h3>
            <p>Here are some job titles that are correct and widely recognised in UK practice:</p>
            <p>✅ Part 1 Architectural Assistant<br />✅ Part 2 Architectural Assistant<br />✅ Architectural Assistant<br />✅ Level 6 / Level 7 Architectural Apprentice<br />✅ Architectural Designer (if you&rsquo;re overseas-qualified but not ARB registered)</p>
            <p>&ldquo;Architectural Designer&rdquo; is a great alternative for experienced professionals working toward registration or those whose qualifications don&rsquo;t yet align with the UK system.</p>
            <p/>
            <p/>
            <h3>I&rsquo;m an Overseas Qualified Architect, how can I register with the ARB?</h3>
            <p>&ldquo;<em>Currently if you hold overseas qualifications obtained outside the EU or hold non-recognised UK qualifications, you will need to pass ARB&rsquo;s Examination for Equivalence to Prescribed Qualifications (also known as the &lsquo;Prescribed Examination&rsquo;) to progress towards registering as an architect in the UK.</em></p>
            <p><em>Once you have satisfied Part 1 and Part 2 requirements, either by obtaining qualifications or by passing the Prescribed Examination, you will then need to obtain a UK Part 3 qualification from a UK school of architecture. Please note if you hold EU qualifications at degree level, that is equivalent to Part 1, you will be required to sit the examination at Part 1 level as the Directive does not provide for qualifications at degree level only.</em></p>
            <p><em>For Part 1 Examination you must have successfully completed a three-year course of study; for Part 2 you must already hold Part 1 and have completed two further years of postgraduate study in architecture.</em></p>
            <p><em>If you hold qualifications that are not principally in architecture, for example, you have a degree in Interior Architecture, Interior Design or Architectural Technology, it is unlikely you will be eligible to sit the examination unless you fall under the current transitional arrangements </em><a href="https://arb.org.uk/wp-content/uploads/2016/05/Extended-Transitional-Arrangements-for-Prescribed-Examination.pdf" target="_blank" rel="nofollow noopener"><em>set out here</em>.</a>&rdquo;.</p>
            <p>Source: <a href="https://arb.org.uk/architect-information/applying-for-registration-for-the-first-time/i-hold-overseas-non-recognised-uk-qualifications/#:~:text=Currently%20if%20you%20hold%20overseas,an%20architect%20in%20the%20UK." target="_blank" rel="nofollow noopener">ARB website</a></p>
            <p/>
            <p/>
            <h3>Has the UK entered into any Mutual Recognition Agreements with other Countries?</h3>
            <p>Yes, In 2023, the ARB signed Mutual Recognition Agreements (MRAs) with the US (NCARB), Australia (AACA), and New Zealand (NZRAB), providing eligible architects with a pathway to licensure in the UK.</p>
            <p>In 2024, a new Memorandum of Understanding (MoU) between the Hong Kong Institute of Architects (HKIA) and the ARB enables the mutual recognition of qualifications, simplifying the process for eligible individuals to have their qualifications recognised in both the UK and Hong Kong.</p>
            <p>For details on eligibility and application, <a href="https://arb.org.uk/international-routes/">ARB website</a>.</p>
            <p/>
            <p/>
            <h3>Final Thoughts</h3>
            <p>If you&rsquo;re serious about building your career in the UK, it&rsquo;s important to show that you understand and respect how the profession is regulated here.</p>
            <p>Using the correct job title isn&rsquo;t just about compliance - it reflects your professionalism, builds trust with employers and helps avoid unnecessary confusion or delays in the hiring process.</p>
            <p>Take the time to get it right, it&rsquo;s a small change that makes a big difference.</p>
            </ArticleFrame>
    }
}